import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { GoogleOAuthProvider } from '@react-oauth/google';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <GoogleOAuthProvider
        clientId={'114425804338-99fd8esflvh0fvn3s2asju6is83kt8it.apps.googleusercontent.com'}
      >
        <DefaultSeo />
        <Component {...pageProps} />
      </GoogleOAuthProvider>
    </>
  );
}

export default MyApp;
